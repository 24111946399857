import classnames from 'classnames';
import React, { forwardRef } from 'react';

import { useTheme } from '../../contexts/Theme';
import { hexToRgba } from '../../lib/colors';
import { ExternalLinkIcon } from '../Icons/ExternalLinkIcon';
import { LoadingSpinner } from '../LoadingSpinner';

interface BaseProps {
  disabled?: boolean;
  focusable?: boolean;
  fullWidth?: boolean;
  iconAfter?: JSX.Element;
  iconBefore?: JSX.Element;
  loading?: boolean;
  noIcon?: boolean;
  size?: 'medium' | 'small';
  variant?: 'primary' | 'secondary' | 'warning' | 'danger' | 'ghost';
}

interface ButtonPropsWithHref
  extends React.AnchorHTMLAttributes<HTMLAnchorElement>,
    BaseProps {
  href: string;
}

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    BaseProps {
  href?: string;
  type?: 'button' | 'reset' | 'submit';
}

type Props = ButtonProps | ButtonPropsWithHref;

export const Button = forwardRef<HTMLAnchorElement | HTMLButtonElement, Props>(
  function Button(
    {
      children,
      className,
      disabled = false,
      fullWidth,
      href,
      iconAfter = null,
      iconBefore = null,
      loading = false,
      noIcon = false,
      size = 'medium',
      type = 'button',
      variant = 'primary',
      focusable = variant === 'ghost',
      ...props
    },
    ref
  ) {
    const { colors, fontFamilies, fontSizes, lineHeights } = useTheme();
    const classNames = classnames('button', className, size, variant, {
      disabled,
      focusable,
      fullWidth,
      loading,
    });

    const isExternalHref = href && href.match(/^(https?:)?\/\//);
    const linkIcon = href && isExternalHref && !iconAfter && !noIcon && (
      <ExternalLinkIcon size={16} />
    );

    const content = (
      <>
        {iconBefore && <span className="iconBefore">{iconBefore}</span>}
        {children}
        {(iconAfter || linkIcon) && (
          <span className="iconAfter">
            {iconAfter}
            {linkIcon}
          </span>
        )}
        <style jsx>{`
          .iconAfter {
            margin: 0 0 -2px 4px;
          }
          .iconBefore {
            margin: 0 4px -2px 0;
          }
        `}</style>
      </>
    );

    const linkProps: ButtonPropsWithHref = {
      className: classNames,
      href: disabled ? null : href,
      rel: isExternalHref ? 'noopener' : null,
      target: isExternalHref ? '_blank' : null,
      ...(props as ButtonPropsWithHref),
    };

    return (
      <>
        {href ? (
          <a
            ref={ref as React.MutableRefObject<HTMLAnchorElement>}
            {...linkProps}
          >
            {content}
            {loading && (
              <span className="loadingSpinner">
                <LoadingSpinner size={16} />
              </span>
            )}
          </a>
        ) : (
          <button
            ref={ref as React.MutableRefObject<HTMLButtonElement>}
            className={classNames}
            disabled={disabled || loading}
            type={type as ButtonProps['type']}
            {...(props as ButtonProps)}
          >
            {content}
            {loading && (
              <span className="loadingSpinner">
                <LoadingSpinner size={16} />
              </span>
            )}
          </button>
        )}
        <style jsx>{`
          .button {
            align-items: center;
            border: 1px solid transparent;
            border-radius: 8px;
            box-shadow: 0 0 0 0 transparent;
            color: var(--button-color);
            cursor: pointer;
            display: flex;
            flex-direction: row;
            font-family: ${fontFamilies.medium};
            font-size: ${fontSizes.base};
            line-height: ${lineHeights.base};
            margin: 0;
            pointer-events: all;
            position: relative;
            text-decoration: none;
            transition: background 200ms ease, border 200ms ease,
              box-shadow 200ms ease;
            white-space: nowrap;
          }
          a.button {
            display: inline-flex;
          }
          .button:focus {
            outline: none;
          }
          .button::-moz-focus-inner {
            border: 0;
          }
          .primary {
            background-color: ${colors.accent};
            border-color: ${colors.accent};
            color: ${colors.accentText};
          }
          .primary:not(.loading):focus-visible,
          .primary:not(.loading):hover {
            box-shadow: 0 0 0 2px ${hexToRgba(colors.accent, 0.6)};
          }
          .warning:not(.loading):focus-visible,
          .warning:not(.loading):hover {
            box-shadow: 0 0 0 2px ${hexToRgba(colors.system.orange, 0.6)};
          }
          .danger:not(.loading):focus-visible,
          .danger:not(.loading):hover {
            box-shadow: 0 0 0 2px ${hexToRgba(colors.system.red, 0.6)};
          }
          .warning {
            background-color: ${colors.system.orange};
            border-color: ${colors.system.orange};
          }
          .secondary {
            background-color: var(--button-secondary-bg);
            border-color: var(--button-secondary-border);
            color: var(--button-secondary-color);
          }
          .secondary:not(.loading):not([disabled]):focus-visible,
          .secondary:not(.loading):not([disabled]):hover {
            box-shadow: 0 0 0 2px var(--focus-shadow);
            border-color: var(--focus-border);
          }
          .danger {
            background-color: ${colors.system.red};
            border-color: ${colors.system.red};
            color: ${colors.system.white};
          }
          .medium {
            padding: 6px 16px 8px;
          }
          .small {
            padding: 2px 8px 4px;
          }
          .fullWidth {
            justify-content: center;
            width: 100%;
            white-space: normal;
          }
          .ghost {
            background-color: transparent;
            border: none;
            border-radius: 0;
            color: var(--button-ghost-color);
            padding: 0;
          }
          .ghost.focusable {
            border-radius: 2px;
          }
          .focusable:not(.loading):not([disabled]):focus-visible,
          .focusable:not(.loading):not([disabled]):hover {
            border-radius: 2px;
            box-shadow: 0 0 0 2px ${hexToRgba(colors.accent, 0.6)};
          }
          .disabled:not(.loading),
          [disabled]:not(.loading) {
            color: ${colors.supportiveText};
            cursor: default;
          }
          .disabled.primary:not(.loading),
          [disabled].primary:not(.loading),
          .disabled.warning:not(.loading),
          [disabled].warning:not(.loading),
          .disabled.danger:not(.loading),
          [disabled].danger:not(.loading) {
            background-color: var(--button-disabled-bg);
            border-color: var(--button-disabled-border);
            box-shadow: none;
            color: var(--button-disabled-color);
            transform: none;
          }
          .disabled.secondary:not(.loading),
          [disabled].secondary:not(.loading) {
            background-color: var(--button-secondary-disabled-bg);
            border-color: var(--button-secondary-disabled-border);
            color: var(--button-secondary-disabled-color);
          }
          .loading {
            cursor: default;
            opacity: 0.9;
          }
          .loadingSpinner {
            align-items: center;
            background-color: inherit;
            border-radius: inherit;
            bottom: 0;
            display: flex;
            justify-content: center;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
          }
          .button[type='submit'] {
            font-weight: 400;
          }
        `}</style>
      </>
    );
  }
);
