import classnames from 'classnames';
import React from 'react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export const StackItem: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
  ...props
}) => (
  <div className={classnames('stackItem', className)} {...props}>
    {children}
  </div>
);

StackItem.displayName = 'StackItem';
