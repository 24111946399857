import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const ExternalLinkIcon: React.FC<React.PropsWithChildren<Props>> = ({
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      d="M6.5 9.5L12.5 3.5M12.5 3.5H8.5M12.5 3.5V7.5"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6 5C6.27614 5 6.5 4.77614 6.5 4.5C6.5 4.22386 6.27614 4 6 4V5ZM12 10C12 9.72386 11.7761 9.5 11.5 9.5C11.2239 9.5 11 9.72386 11 10H12ZM9.5 12H5.5V13H9.5V12ZM4 10.5V6.5H3V10.5H4ZM5.5 5H6V4H5.5V5ZM11 10V10.5H12V10H11ZM5.5 12C4.67157 12 4 11.3284 4 10.5H3C3 11.8807 4.11929 13 5.5 13V12ZM9.5 13C10.8807 13 12 11.8807 12 10.5H11C11 11.3284 10.3284 12 9.5 12V13ZM4 6.5C4 5.67157 4.67157 5 5.5 5V4C4.11929 4 3 5.11929 3 6.5H4Z" />
    <style jsx>{`
      svg {
        fill: ${color};
        height: ${size}px;
        width: ${size}px;
        display: block;
      }
    `}</style>
  </svg>
);
